import * as React from "react";
export const HomeIcon1 = (props) => (
  <svg
    {...props}
    width="28"
    height="28"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5642 4.99863C17.0029 3.97741 18.9299 3.97741 20.3686 4.99863L29.8932 11.7597C30.9896 12.5379 31.641 13.7992 31.641 15.1437L31.641 25.2456C31.641 27.5376 29.783 29.3956 27.491 29.3956H23.8484V18.6359C23.8484 16.5096 22.1247 14.7859 19.9984 14.7859H15.8749C13.7486 14.7859 12.0249 16.5096 12.0249 18.6359V29.3956H8.4418C6.14982 29.3956 4.29181 27.5376 4.2918 25.2456L4.2918 15.1437C4.2918 13.7992 4.9432 12.5379 6.03961 11.7597L15.5642 4.99863ZM21.3526 3.61238C19.3246 2.17283 16.6082 2.17282 14.5802 3.61238L5.05558 10.3734C3.51004 11.4705 2.5918 13.2484 2.5918 15.1437L2.5918 25.2456C2.59181 28.4765 5.21094 31.0956 8.4418 31.0956H27.491C30.7219 31.0956 33.341 28.4765 33.341 25.2456L33.341 15.1437C33.341 13.2484 32.4227 11.4705 30.8772 10.3734L21.3526 3.61238ZM13.7249 18.6359C13.7249 17.4485 14.6875 16.4859 15.8749 16.4859H19.9984C21.1858 16.4859 22.1484 17.4485 22.1484 18.6359V29.3953H13.7249V18.6359Z"
      fill="rgba(0,0,0,0.6)"
    />
  </svg>
);
