import axios from "axios";
import { makeAutoObservable, toJS } from "mobx";
import { AuthService } from "services/AuthService";
import { REFRESH_TOKEN } from "utils/ApiRoutesConstants/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "axiosInstance";
class AuthStore {
  user = {};
  isAuth = false;
  error = false;
  isLoading = false;
  constructor() {
    makeAutoObservable(this);
  }
  setAuth(bool) {
    this.isAuth = bool;
  }
  setUser(user) {
    this.user = user;
  }
  setError(bool) {
    this.error = bool;
  }
  setLoading(bool) {
    this.isLoading = bool;
  }

  get adminData() {
    return toJS(this.user);
  }

  async loginAdmin(email, password) {
    try {
      const response = await AuthService.loginAdmin(email, password);
      localStorage.setItem("token", response.data.accessToken);
      const adminJson = JSON.stringify(response.data.user);
      localStorage.setItem("admin", adminJson);
      toast.success("You successfully logged in");
      this.setAuth(true);
      this.setUser(response.data.user);
      this.setError(false);
      return response.data;
    } catch (error) {
      this.setError(true);
      toast.error("Password or email is invalid");
      console.log(error);
      return null;
    }
  }
  async registrationAdmin(email, password) {
    try {
      const response = await AuthService.registrationAdmin(email, password);
      const adminJson = JSON.stringify(response.data.user);
      localStorage.setItem("admin", adminJson);
      localStorage.setItem("token", response.data.accessToken);
      toast.success("You successfully registrated in");
      this.setAuth(true);
      this.setUser(response.data.user);
      this.setError(false);
      return response.data;
    } catch (error) {
      toast.error("Password or email is invalid");
      this.setError(true);
      console.log(error);
      return null;
    }
  }

  async logout() {
    try {
      await AuthService.logout();
      this.setAuth(false);
      this.setUser({});
      this.setError(false);
      localStorage.removeItem("token");
      localStorage.removeItem("admin");
    } catch (error) {
      this.setError(true);
      console.log(error);
      return null;
    }
  }
  async checkAuth() {
    this.setLoading(true);
    try {
      const response = await axios.get(`${baseURL}${REFRESH_TOKEN}`, {
        withCredentials: true,
      });
      const adminJson = JSON.stringify(response.data.user);
      localStorage.setItem("admin", adminJson);
      localStorage.setItem("token", response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
      this.setError(false);
    } catch (error) {
      this.setError(true);
      console.log(error);
      return null;
    } finally {
      this.setLoading(false);
    }
  }
}
const authStore = new AuthStore();
export { authStore };
