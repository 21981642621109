import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.scss";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import { theme, ThemeProvider } from "./theme";

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
  <ThemeProvider theme={theme}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </ThemeProvider>
);
