import { useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import ListStory from "pages/StoryList/ListStory";
import AOS from "aos";
import Error from "components/ErrorPage/Error";
import "aos/dist/aos.css";
import { Navigate } from "react-router-dom";
import { AdminSkeleton } from "pages/Admin/AdminSkeleton";
import { authStore } from "store/AuthStore";
import {
  LOGIN_PAGE,
  ADMIN,
  WEDDINGS,
  FAMILY,
  LOVESTORY,
  HOME,
  SINGLE_STORY,
} from "utils/RouterConstants/routerConstants";
import { ToastContainer } from "react-toastify";
import { Layout } from "Layout/Layout";
import { SingleStory } from "pages/StoryList/SingleStory/SingleStory";
import ScrollToHashElement from "archiveComponents/HashLink/HashLink";
const Admin = lazy(() => import("pages/Admin/Admin"));
const Auth = lazy(() => import("pages/Auth/Auth"));

function App() {
  useEffect(() => {
    if (localStorage.getItem("token")) {
      authStore.checkAuth();
    }

    AOS.init({
      duration: 1200,
      offset: 50,
      once: true,
    });
  }, []);

  return (
    <BrowserRouter>
      <ScrollToHashElement />
      <Routes>
        <Route path={HOME} element={<Layout />}>
          <Route path={HOME} element={<Home />} />
          <Route path={WEDDINGS} element={<ListStory />} />
          <Route path={LOVESTORY} element={<ListStory />} />
          <Route path={FAMILY} element={<ListStory />} />
          <Route path={SINGLE_STORY} element={<SingleStory />} />
          <Route
            path={ADMIN}
            element={
              <Suspense fallback={<AdminSkeleton />}>
                <Admin />
              </Suspense>
            }
          />
          <Route
            path={LOGIN_PAGE}
            element={
              <Suspense fallback={<div>"Auth page loading"</div>}>
                <Auth />
              </Suspense>
            }
          />
          <Route path="/404" element={<Error />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Route>
      </Routes>
      <ToastContainer position="top-right" autoClose={3000} theme="dark" />
    </BrowserRouter>
  );
}

export default App;
