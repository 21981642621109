import React from "react";

export const CameraIcon1 = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7282 7.58267C11.7282 6.48721 12.6162 5.59917 13.7117 5.59917H21.3109C22.4064 5.59917 23.2944 6.48721 23.2944 7.58267C23.2944 9.61701 24.9436 11.2662 26.9779 11.2662H28.6449C30.3846 11.2662 31.7949 12.6765 31.7949 14.4162V26.2509C31.7949 27.9906 30.3846 29.4009 28.6449 29.4009H6.37783C4.63813 29.4009 3.22783 27.9906 3.22783 26.2509V14.4162C3.22783 12.6765 4.63813 11.2662 6.37783 11.2662H8.04468C10.079 11.2662 11.7282 9.61701 11.7282 7.58267ZM13.7117 3.89917C11.6773 3.89917 10.0282 5.54833 10.0282 7.58267C10.0282 8.67812 9.14014 9.56617 8.04468 9.56617H6.37783C3.69925 9.56617 1.52783 11.7376 1.52783 14.4162V26.2509C1.52783 28.9295 3.69925 31.1009 6.37783 31.1009H28.6449C31.3235 31.1009 33.4949 28.9295 33.4949 26.2509V14.4162C33.4949 11.7376 31.3235 9.56617 28.6449 9.56617H26.9779C25.8825 9.56617 24.9944 8.67812 24.9944 7.58267C24.9944 5.54833 23.3453 3.89917 21.3109 3.89917H13.7117ZM11.8172 19.279C11.8172 16.1341 14.3666 13.5848 17.5114 13.5848C20.6562 13.5848 23.2056 16.1341 23.2056 19.279C23.2056 22.4238 20.6562 24.9731 17.5114 24.9731C14.3666 24.9731 11.8172 22.4238 11.8172 19.279ZM17.5114 11.8848C13.4277 11.8848 10.1172 15.1953 10.1172 19.279C10.1172 23.3627 13.4277 26.6731 17.5114 26.6731C21.5951 26.6731 24.9056 23.3627 24.9056 19.279C24.9056 15.1953 21.5951 11.8848 17.5114 11.8848Z"
        fill="rgba(0,0,0,0.6)"
      />
    </svg>
  );
};
