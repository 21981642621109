import { ThemeProvider, createTheme } from "@mui/material/styles";
export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "inherit",
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          backgroundColor: "orange",
        },
        root: {
          backgroundColor: "rgba(255, 203, 105, 0.386)",
          borderRadius: 5,
          height: 8,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "rgba(0,0,0,0.6)",

          "&.Mui-selected": {
            color: "rgba(0,0,0,1)",
          },
        },
        label: {
          fontSize: "0.85rem",
          "&.Mui-selected": {
            fontSize: "0.95rem",
          },
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          width: "100%",
          borderRadius: "20px",
        },
      },
    },
  },
});
export { ThemeProvider };
