import { $api } from "axiosInstance";
import { STORY, STORY_EDIT, IMAGES_EDIT } from "utils/ApiRoutesConstants/story";
class StoryService {
  static async getStories() {
    try {
      return await $api.get(STORY);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static async createStory(story) {
    try {
      return await $api.postForm(STORY, story);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static async getStoryById(storyId) {
    try {
      return await $api.get(`${STORY}/${storyId}`);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static async deletStoryById(storyId) {
    try {
      return await $api.delete(`${STORY}/${storyId}`);
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static async getStoryByCategory(category) {
    try {
      const url =
        category === "all-stories"
          ? `${STORY}/`
          : `${STORY}/?category=${category}`;
      return await $api.get(url);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  static async editStory(story) {
    try {
      return await $api.put(`${STORY_EDIT}`, story);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  static async deleteOneFoto(id, filename) {
    try {
      return await $api.delete(`${STORY_EDIT}/${id}`, { data: { filename } });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static async addPhotoToExistingStory(id, data) {
    try {
      return await $api.patchForm(`${STORY_EDIT}/${id}`, data);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
export { StoryService };
