export const storyCat = [
  {
    value: "wedding",
    label: "Wedding",
  },
  {
    value: "engagement",
    label: "Engagement",
  },
  {
    value: "family",
    label: "Family",
  },
  {
    value: "content",
    label: "Content",
  },
];
