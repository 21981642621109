import React from "react";
import styles from "./About.module.scss";
import style from "../../style/Container.module.scss";
const { container } = style;

const About = () => {
  return (
    <section className={styles.about}>
      <div className={container}>
        <div className={styles.about_inner}>
          <div className={styles.photo} data-aos="fade-up">
            <img src="/about/myFoto.webp" alt="Julia Kulyok" loading="lazy" />
          </div>
          <div data-aos="fade-up" className={styles.about_description}>
            <h4>Hello I am Julia</h4>
            <p className={styles.about_bold}>Photography for me is life</p>
            <p>
              I first picked up a camera at the age of 11. At 17, I realized
              that people liked my photos and decided to become a photographer.
              At 18, specifically in the 11th grade, I shot my first wedding.
              And now, for 7 years, I have been capturing love through my
              photography.
            </p>
            <p className={styles.about_bold}>
              My photographs are about: love, emotions, aesthetics, life,
              sincerity.
            </p>
            <p>
              In life, the most important things for me are: love, family,
              creativity, moments. All of this is about wedding photography. I
              get a kick out of my work; it's important for me to preserve the
              touching story of a family in photographs.
            </p>
            <p>
              I strive for my photographs to reflect you and the atmosphere of
              your celebration, so I approach each couple individually. I don't
              shoot another wedding by the template; it's important for me to
              feel connected to couple. My superpower is capturing people
              authentically in photos. I see the beauty in everyone and know how
              to highlight it.
            </p>
            <p>
              Here you can familiarize yourself with my work, and on{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.insta}
                href="https://www.instagram.com/juliabond.ph"
              >
                Instagram
              </a>
              , you can get to know me better.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
