import React from "react";
import { Skeleton } from "@mui/material";
export const GallerySkeleton = () => {
  return Array.from({ length: 14 }, (_, index) => {
    return (
      <div key={index} className="gallery__item">
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={"100%"}
        />
      </div>
    );
  });
};
