import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { StoryService } from "services/StoryService";
import { HelmetSingleStory } from "./HelmetSingleStory";
import ListImageGallery from "components/Gallery/ListImageGallery";
import { CoverImage } from "./CoverImage";

export const SingleStory = () => {
  const [loading, setLoading] = useState(true);
  const { category, id } = useParams();
  const [story, setStory] = useState({});
  const { pathname } = useLocation();
  const [coverImage, setCoverImage] = useState();
  useEffect(() => {
    const fetchStory = async () => {
      const response = await StoryService.getStoryById(id);
      if (!response) {
        return;
      }
      setStory(response.data);
      const cover = response.data.photo.find((val) => {
        return val.cover === true;
      }).publicUrl;
      setCoverImage(cover);
      setLoading(false);
    };
    fetchStory();
  }, [id]);

  return (
    <>
      <HelmetSingleStory eventType={category} rout={pathname} />
      <CoverImage isLoading={loading} story={story} coverImage={coverImage} />

      <ListImageGallery
        isLoading={loading}
        photo={story.photo}
        fullStory={story}
      />
    </>
  );
};
