import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { StoryCard } from "../../components/StoryCard/StoryCard";
import { StoryService } from "services/StoryService";
import style from "./ListStory.module.scss";
import { storyCat } from "utils/storyCategories";
import { styled } from "@mui/system";
import weddingImage from "../../assets/coverImages/WeddingCover.webp";
import familyImage from "../../assets/coverImages/family.jpg";
import LsImage from "../../assets/coverImages/LS.jpg";
import { HelmetStoryList } from "./HelmetStoryList";

const ListStory = () => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const category = pathname.slice(1);
  let coverImage;
  useEffect(() => {
    const fetchStories = async () => {
      const response = await StoryService.getStoryByCategory(category);
      if (!response) {
        return;
      }
      setStories(response.data);
      setLoading(false);
    };
    fetchStories();
  }, [category]);

  const [label] = storyCat.filter((cat) => cat.value === category);

  switch (category) {
    case "wedding":
      coverImage = weddingImage;
      break;
    case "family":
      coverImage = familyImage;
      break;
    case "engagement":
      coverImage = LsImage;
      break;
    default:
      coverImage = weddingImage;
  }

  return (
    <>
      <HelmetStoryList rout={pathname} eventType={category} />
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          sx={{
            width: "100%",
            height: "70vh",
            objectFit: "cover",
          }}
          src={coverImage}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.45)",
          }}
        />

        <Stack
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            color: "white",
            transform: "translate(-50%,-50%)",
          }}
          direction={"column"}
          alignItems={"center"}
        >
          <StoryListTitle>{label.label}</StoryListTitle>
        </Stack>
      </Box>

      {stories.map((story) => {
        return <StoryCard isLoading={loading} key={story._id} story={story} />;
      })}
    </>
  );
};

export const StoryListTitle = styled("h2")(({ theme }) =>
  // функція unstable_sx дозволяє використовувати синтаксис sx в styled функції, що дозволяє вказувати breakpoints для певних css властивостей
  theme.unstable_sx({
    textAlign: "center",
    fontSize: { xs: "30px", sm: "3px", md: "34px", lg: "40px" },
    fontWeight: 300,
    my: { lg: 3, md: 2, xs: 1 },
  })
);
export default ListStory;
