import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ADMIN, HOME } from "utils/RouterConstants/routerConstants";
import styles from "./Header.module.scss";
import Dropdown from "./Dropdown/Dropdown";
import { authStore } from "store/AuthStore";
import { observer } from "mobx-react-lite";
import { styled } from "@mui/system";

const Header = () => {
  const [show, setShow] = useState(false);

  const mouseEnter = () => {
    setShow(true);
  };
  const mouseLeave = () => {
    setShow(false);
  };

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <Link className={styles.logo} to={HOME}>
          <HeaderLogo>Julia Bond</HeaderLogo>
          <HeaderSubLogo>Photographer</HeaderSubLogo>
        </Link>
        <ul className={styles.menu}>
          <li
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            className={styles.menu_item}
          >
            Portfolio
            {show && <Dropdown />}
          </li>
          <li className={styles.menu_item}>
            <Link to={HOME}>Home</Link>
          </li>

          {authStore.isAuth && (
            <li className={styles.menu_item}>
              <Link to={ADMIN}>Admin</Link>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

const HeaderLogo = styled("h1")(({ theme }) =>
  // функція unstable_sx дозволяє використовувати синтаксис sx в styled функції, що дозволяє вказувати breakpoints для певних css властивостей
  theme.unstable_sx({
    letterSpacing: 4,
    textAlign: "center",
    fontSize: { xs: "18px", sm: "18px", md: "20px", lg: "24px" },
  })
);
const HeaderSubLogo = styled("p")(({ theme }) =>
  // функція unstable_sx дозволяє використовувати синтаксис sx в styled функції, що дозволяє вказувати breakpoints для певних css властивостей
  theme.unstable_sx({
    letterSpacing: 6,
    textAlign: "center",
    fontSize: { xs: "11px", sm: "12px", md: "13px", lg: "14px" },
    color: "rgba(13, 28, 36, 0.85)",
    marginTop: 0.5,
  })
);
export default observer(Header);
