import React from "react";
import { Skeleton, Container, Stack } from "@mui/material";

export const AdminSkeleton = () => {
  return (
    <Container fixed>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width={"100%"} height={100} />
        <Stack spacing={2} direction="row">
          <Skeleton variant="rectangular" width={"30%"} height={800} />
          <Skeleton variant="rectangular" width={"70%"} height={800} />
        </Stack>
      </Stack>
    </Container>
  );
};
