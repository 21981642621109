import { IoIosMail, IoLogoInstagram } from "react-icons/io";
import { FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import {
  AiOutlineMenu,
  AiOutlineCloseCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { BsArrowDown } from "react-icons/bs";
import { MdPhoneIphone } from "react-icons/md";
import { PiIdentificationCardThin } from "react-icons/pi";

import { CameraIcon1 } from "./CameraIcon";
import { HomeIcon1 } from "./HomeIcon";

export const TelegramIcon = (args) => (
  <FaTelegramPlane color={"#31363F"} {...args} />
);
export const MailIcon = (args) => <IoIosMail color="#31363F" {...args} />;
export const InstagramIcon = (args) => (
  <IoLogoInstagram color="#31363F" {...args} />
);
export const ArrowDownIcon = (args) => <BsArrowDown {...args} />;
export const BurgerIcon = (args) => <AiOutlineMenu {...args} />;
export const CloseMenuIcon = (args) => <AiOutlineCloseCircle {...args} />;
export const WhatsAppIcon = (args) => <FaWhatsapp color="#31363F" {...args} />;
export const PhoneIcon = (args) => <MdPhoneIphone color="#31363F" {...args} />;
export const CameraIcon = (args) => <CameraIcon1 {...args} />;
export const ContactCardIcon = (args) => <PiIdentificationCardThin {...args} />;
export const HomeIcon = (args) => <HomeIcon1 {...args} />;
export const PlusCircle = (args) => <AiOutlinePlusCircle {...args} />;
