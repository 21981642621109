import React from "react";
import { Skeleton } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import style from "./ListImageGallery.module.scss";
export const ListImageSkeleton = () => {
  return Array.from({ length: 9 }, (_, index) => {
    return (
      <ImageListItem key={index}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={style.skeleton}
        />
      </ImageListItem>
    );
  });
};
