import React from "react";
import useDetectScroll from "@smakss/react-scroll-direction";
import { ArrowDownIcon } from "components/UI/icons";
import clsx from "clsx";
import { scrollWindow } from "utils/scrollWindow";
import style from "./buttonToTop.module.scss";

export const ButtonToTop = () => {
  const { scrollPosition } = useDetectScroll({
    thr: 1,
    scrollDown: false,
    scrollUp: true,
    still: false,
  });
  const show = scrollPosition.top > scrollPosition.bottom;
  const scrolTop = () => {
    scrollWindow({ directon: "top", value: -window.scrollY });
  };
  return (
    <>
      <div
        onClick={scrolTop}
        className={clsx(style.go_top_button, { [style.active]: show })}
      >
        <ArrowDownIcon className={style.arrow} />
      </div>
    </>
  );
};
