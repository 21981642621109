import React, { useEffect, useState } from "react";
import Gallery from "components/Gallery/Gallery";
import About from "components/About/About";
import { getPathsImages } from "utils/getPathsImage";
import style from "./Home.module.scss";

const images = require.context(
  "../../assets/homeGallery",
  false,
  /\.(jpe?g|webp)$/
);

const Home = () => {
  const [imagePaths, setImagePath] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setImagePath(getPathsImages(images));
    setLoading(false);
  }, []);

  return (
    <div className={style.home}>
      <Gallery isLoading={loading} images={imagePaths} />
      <About />
    </div>
  );
};

export default Home;
