import { $api } from "axiosInstance";
import {
  LOGIN,
  REGISTRATION,
  LOGOUT,
  AUTH,
} from "utils/ApiRoutesConstants/auth";

class AuthService {
  static async loginAdmin(email, password) {
    return $api.post(LOGIN, { email, password });
  }

  static async registrationAdmin(email, password) {
    return $api.post(REGISTRATION, { email, password });
  }

  static async logout() {
    return $api.post(LOGOUT);
  }

  static async getAdmin() {
    try {
      return await $api.get(AUTH);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
export { AuthService };
