export const scrollWindow = ({
  directon = "top",
  value,
  behavior = "smooth",
}) => {
  window.scrollBy({
    [directon]: value,
    behavior: behavior,
  });
};
