import React from "react";
import { Helmet } from "react-helmet-async";

export const HelmetStoryList = ({ rout, eventType }) => {
  return (
    <Helmet>
      <link rel="canonical" href={`https://juliabondph.com${rout}`} />
      <title>{`Julia Bond Photography ${eventType} portfolio `}</title>
      <meta
        name="description"
        data-rh="true"
        content="Photographer in Ireland. Photographer in the Paris, Italy, Spain, Montenegro. 
         Wedding photographer. Photographer Kilkenny. Freeze your date by booking the photoshoot"
      />
    </Helmet>
  );
};
