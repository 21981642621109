import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "components/Header/Header";
import { Footer } from "components/Footer/Footer";
import { ButtonToTop } from "components/ButtonToTop/ButtonToTop";
import style from "./Layout.module.scss";
import { BottomMenu } from "components/BottomMenu/BottomMenu";

export const Layout = () => {
  const { pathname } = useLocation();
  // If location has changed - scroll page to very top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className={style.pg}>
      {!pathname.includes("admin") && <Header />}
      <main className={style.main}>
        {
          <>
            <Outlet />
            <ButtonToTop />
          </>
        }
      </main>
      <BottomMenu />

      {!pathname.includes("admin") && <Footer />}
    </div>
  );
};
