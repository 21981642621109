import { EffectCoverflow, Mousewheel, FreeMode } from "swiper/modules";

export const swiperOptions = {
  loop: true,
  effect: "coverflow",
  coverflowEffect: {
    rotate: 0,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  },
  spaceBetween: "10px",
  slidesPerView: 3,
  centeredSlides: true,
  modules: [EffectCoverflow, Mousewheel, FreeMode],
  freeMode: true,
};
