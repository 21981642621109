export const getAgent = () => {
  const userAgent = navigator.userAgent;

  let deviceType;

  if (userAgent.match(/iPhone|iPad|iPod|Android/i)) {
    deviceType = "Mobile";
  } else if (userAgent.match(/Windows/i)) {
    deviceType = "PC";
  } else if (userAgent.match(/Macintosh|Mac OS/i)) {
    deviceType = "MacBook";
  } else {
    deviceType = null;
  }
  return deviceType;
};
