import axios from "axios";
import { REFRESH_TOKEN } from "utils/ApiRoutesConstants/auth";

export const baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_PROD;

const $api = axios.create({
  withCredentials: true,
  baseURL: baseURL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});
$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._isRetry &&
      error.config
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(`${baseURL}${REFRESH_TOKEN}`, {
          withCredentials: true,
        });
        localStorage.setItem("token", response.data.accessToken);
        return $api.request(originalRequest);
      } catch (error) {
        console.log("Authorization invalid or session ended ");
      }
    }
    throw error;
  }
);

export { $api };
