import React from "react";
import { Link } from "react-router-dom";
import {
  TelegramIcon,
  InstagramIcon,
  MailIcon,
  PhoneIcon,
  WhatsAppIcon,
} from "components/UI/icons";
import style from "./footer.module.scss";
import { styled } from "@mui/system";

export const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.links}>
        <Link className={style.logo} to={"/"}>
          <FooterLogo>Julia Bond</FooterLogo>
          <FooterSubLogo>Photographer</FooterSubLogo>
        </Link>
        <div>
          <ul className={style.social}>
            <li>
              <a
                href="https://t.me/juliakulyok"
                target="_blank"
                rel="noreferrer"
                className={style.social_item}
              >
                <TelegramIcon />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/juliabond.ph"
                className={style.social_item}
              >
                <InstagramIcon />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:uliakvat@gmail.com"
                className={style.social_item}
              >
                <MailIcon />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="tel:+353851581146"
                className={style.social_item}
              >
                <PhoneIcon />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/+353851581146"
                className={style.social_item}
              >
                <WhatsAppIcon />
              </a>
            </li>
          </ul>
          <p className={style.social_text}>Contact me</p>
        </div>
      </div>

      <div className={style.copyright}>
        © 2024 Julia Bond wedding, family and portrait photographer
      </div>
    </footer>
  );
};
const FooterLogo = styled("h3")(({ theme }) =>
  // функція unstable_sx дозволяє використовувати синтаксис sx в styled функції, що дозволяє вказувати breakpoints для певних css властивостей
  theme.unstable_sx({
    letterSpacing: 4,
    fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
    color: "rgba(13, 28, 36, 0.85)",
  })
);
const FooterSubLogo = styled("p")(({ theme }) =>
  // функція unstable_sx дозволяє використовувати синтаксис sx в styled функції, що дозволяє вказувати breakpoints для певних css властивостей
  theme.unstable_sx({
    letterSpacing: 6,
    fontSize: { xs: "10px", sm: "11px", md: "12px", lg: "14px" },
    color: "rgba(13, 28, 36, 0.85)",
    marginTop: 0.5,
  })
);
