import { useState } from "react";
import PhotoItem from "./PhotoItem/PhotoItem";
import "./Gallery.scss";
import { GallerySkeleton } from "./GallerySkeleton";
import { JLightbox } from "./Lightbox";

const Gallery = ({ isLoading, images, fullStory }) => {
  const [open, setOpen] = useState(-1);

  return (
    <div className="gallery">
      <div className="gallery__inner">
        {isLoading ? (
          <GallerySkeleton />
        ) : (
          images.map((photo, i) => {
            return (
              <PhotoItem
                openLightbox={() => setOpen(i)}
                key={i}
                photo={photo}
                fullStory={fullStory}
              />
            );
          })
        )}
      </div>
      <JLightbox isOpen={open} open={setOpen} images={images} />
    </div>
  );
};

export default Gallery;
