import { useInView } from "react-intersection-observer";
const PhotoItem = ({ photo, openLightbox, fullStory }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const path = typeof photo === "string" ? photo : photo.publicUrl;

  const altText =
    fullStory === undefined ? "This is a main page images" : fullStory.title;

  return (
    <div
      ref={ref}
      className="gallery__item"
      data-aos="zoom-in"
      data-aos-duration="300"
    >
      {inView ? (
        <img onClick={openLightbox} src={path} alt={altText} />
      ) : (
        <div
          data-aos="zoom-in"
          data-aos-duration="300"
          className="gallery__skeleton"
        ></div>
      )}
    </div>
  );
};

export default PhotoItem;
