import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import { getAgent } from "utils/getAgent";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";

export const JLightbox = ({ images, open, isOpen }) => {
  const agent = getAgent();

  const hideArrowOnMobileDevices =
    agent === "Mobile"
      ? {
          buttonNext: () => null,
          buttonPrev: () => null,
        }
      : {};
  return (
    <Lightbox
      carousel={{ padding: "0px", spacing: "10%" }}
      controller={{
        closeOnPullUp: true, // if true, close the lightbox on pull-up gesture
        closeOnPullDown: true, // if true, close the lightbox on pull-down gesture
      }}
      index={isOpen}
      plugins={[Fullscreen, Counter, Zoom, Slideshow]}
      open={isOpen >= 0}
      close={() => open(-1)}
      slides={images.map((url) => {
        const src = typeof url === "string" ? url : url.publicUrl;
        return { src };
      })}
      render={hideArrowOnMobileDevices}
    />
  );
};
