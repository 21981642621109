import React from "react";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import style from "./storyCard.module.scss";
export const StoryCardSkeleton = () => {
  return (
    <>
      <Card className={style.skeleton}>
        <Skeleton variant="text" className={style.skeleton_title} />
        <Skeleton variant="text" className={style.skeleton_location} />
        <Skeleton variant="rounded" className={style.skeleton_swiper} />
      </Card>
      <Card className={style.skeleton}>
        <Skeleton variant="text" className={style.skeleton_title} />
        <Skeleton variant="text" className={style.skeleton_location} />
        <Skeleton variant="rounded" className={style.skeleton_swiper} />
      </Card>
    </>
  );
};
