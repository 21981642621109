import { useState } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { ArrowDownIcon } from "components/UI/icons";
import { scrollWindow } from "utils/scrollWindow";
import style from "./CoverImage.module.scss";
import { CoverImageSkeleton } from "./CoverImageSkeleton";
export const CoverImage = ({ isLoading, story, coverImage }) => {
  const [showArrow, setShowArrow] = useState(true);
  const scrollDown = () => {
    if (showArrow) {
      scrollWindow({ directon: "top", value: 600 });
    }
    setShowArrow(false);
  };
  return (
    <>
      {isLoading ? (
        <CoverImageSkeleton />
      ) : (
        <Box sx={{ position: "relative" }}>
          <img
            className={style.coverImage}
            src={coverImage}
            alt={story.storyName}
          />

          <div className={style.overlay}></div>

          <Stack
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              color: "white",
              transform: "translate(-50%,-50%)",
            }}
            direction={"column"}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontSize: {
                  lg: "28px",
                  md: "20px",
                  xs: "18px",
                },
                fontWeight: 400,
                letterSpacing: 3,
                marginBottom: 2,
                whiteSpace: "nowrap",
              }}
            >
              {story.storyName}
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "14px", md: "14px", xs: "12px" },
                letterSpacing: 2,
                whiteSpace: "nowrap",
                marginBottom: 4,
                color: "white",
                opacity: 0.5,
              }}
            >
              {`${story.location},  ${story.date}`}
            </Typography>
            <Box sx={{ width: { lg: "450px", md: "350", xs: "250px" } }}>
              <Typography
                sx={{
                  fontSize: {
                    lg: "16px",
                    md: "14px",
                    xs: "13px",
                    color: "lightgray",
                  },
                  textAlign: "center",
                }}
              >
                {story.describe}
              </Typography>
            </Box>
          </Stack>
          {showArrow && (
            <Box className={style.arrow} onClick={scrollDown}>
              <ArrowDownIcon fontSize={20} color="white" />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
