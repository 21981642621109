import React, { useState } from "react";
import "./dropdown.scss";
import { Link } from "react-router-dom";
import {
  LOVESTORY,
  WEDDINGS,
  FAMILY,
} from "utils/RouterConstants/routerConstants";

function Dropdown() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <ul
      onClick={handleClick}
      className={click ? "dropdown-menu clicked" : "dropdown-menu"}
    >
      <li>
        <Link className={"dropdown-link"} to={LOVESTORY}>
          Engagement
        </Link>
      </li>
      <li>
        <Link className={"dropdown-link"} to={WEDDINGS}>
          Wedding
        </Link>
      </li>
      <li>
        <Link className={"dropdown-link"} to={FAMILY}>
          Family
        </Link>
      </li>
    </ul>
  );
}

export default Dropdown;
