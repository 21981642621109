import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import style from "./CoverImage.module.scss";
export const CoverImageSkeleton = () => {
  return (
    <Card className={style.skeleton}>
      <Skeleton className={style.skeleton_title} />
      <Skeleton className={style.skeleton_location} />
      <Skeleton className={style.skeleton_text} />
    </Card>
  );
};
