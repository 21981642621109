import { Link } from "react-router-dom";
import { Divider } from "@mui/material";
import style from "./submenu.module.scss";
import {
  LOVESTORY,
  WEDDINGS,
  FAMILY,
} from "utils/RouterConstants/routerConstants";

export const SubMenu = ({ closeModal, open }) => {
  return (
    <div className={style.submenu}>
      <p className={style.submenu_title}>Let's have a look at </p>
      <Divider />
      <ul className={style.submenu_list}>
        <li onClick={closeModal} className={style.submenu_item}>
          <Link className={style.submenu_link} to={LOVESTORY}>
            Engagement
          </Link>
        </li>
        <Divider />
        <li onClick={closeModal} className={style.submenu_item}>
          <Link className={style.submenu_link} to={WEDDINGS}>
            Wedding
          </Link>
        </li>
        <Divider />
        <li onClick={closeModal} className={style.submenu_item}>
          <Link className={style.submenu_link} to={FAMILY}>
            Family
          </Link>
        </li>
      </ul>
    </div>
  );
};
