import * as React from "react";
import { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import style from "./ListImageGallery.module.scss";
import { JLightbox } from "./Lightbox";
import { ListImageSkeleton } from "./ListImageSkeleton";
export default function ListImageGallery({ isLoading, photo, fullStory }) {
  const [open, setOpen] = useState(-1);
  const altText =
    fullStory === undefined ? "This is a main page images" : fullStory.title;

  return (
    <div className={style.gallery}>
      <ImageList variant="masonry" gap={8} className={style.list}>
        {isLoading ? (
          <ListImageSkeleton />
        ) : (
          photo.map((item, i) => {
            const path = item.publicUrl;
            return (
              <ImageListItem className={style.item} key={path}>
                <img
                  onClick={() => setOpen(i)}
                  src={path}
                  alt={altText}
                  loading="lazy"
                />
              </ImageListItem>
            );
          })
        )}
      </ImageList>
      {!isLoading && <JLightbox open={setOpen} isOpen={open} images={photo} />}
    </div>
  );
}
