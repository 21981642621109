export const HOME = "/";
export const ADMIN = "/admin/*";
export const LOGIN_PAGE = "/login";

export const WEDDINGS = "wedding";
export const FAMILY = "family";
export const CONTENT = "content";
export const LOVESTORY = "engagement";
export const SINGLE_STORY = "/:category/:id";
export const EDIT_STORY_BY_ID = "edit/story/:id";
export const ADD_STORY = "add-story";
